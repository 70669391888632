import React, { Component } from "react"
import styled from "styled-components"
import { styles } from "../../utils"
import { Section, QuickInfoTitle } from "../../utils"

export default class QuickInfo extends Component {
  render() {
    return (
      <QuickInfoWrapper>
        <Section>
          <div className="row">
            <div className="col-sm-4" align="center">
              <QuickInfoTitle title="Hours" />
              <p className="text">
                <strong>Tues-Thurs: 11am to 2pm AND 4pm to 9pm</strong>
                <br />
                <strong>Fri: 11am to 9pm</strong>
                <br />
                <strong>Sat 10am to 9pm</strong>
                <br />
                <strong>Sun 10am to 9pm</strong>
                <br />
                <strong>Monday: Closed</strong>
              </p>
            </div>
            <div className="col-sm-4" align="center">
              <QuickInfoTitle title="Location" />
              <p className="text">
                <strong>13500 Clemson Blvd.</strong>
                <br />
                <strong>Seneca, SC 29678</strong>
                <br />
                <strong>
                  <a
                    href="https://goo.gl/maps/1TmLn6ApHU6w5Hku5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (View Map)
                  </a>
                </strong>
              </p>
            </div>
            <div className="col-sm-4" align="center">
              <QuickInfoTitle title="Contact" />
              <p className="text">
                <strong>Now Accepting</strong>
                <br />
                <strong>Reservations</strong>
                <br />
                <a href="tel:864-722-2800" rel="noopener noreferrer">864-722-2800</a>
              </p>
            </div>
          </div>
        </Section>
      </QuickInfoWrapper>
    )
  }
}

const QuickInfoWrapper = styled.div`
  background: #263746;
  .text {
    font-size: 1.2rem;
    color: #fff;
  }
  a {
    color: ${styles.colors.mainOrange};
  }
  h3 {
    text-transform: uppercase;
  }
`
