import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, HomeHeader, BannerCenter, BannerButton } from "../utils"
import lakeShore from "../images/bcg/clemson-restaurant-on-lake-hartwell.jpg"
import food from "../images/bcg/crab-cakes-red-wine.jpg"
import QuickInfo from "../components/HomePageComponents/QuickInfo"

const MenuPage = ({ location }) => (
  <Layout>
    <SEO
      title="Menu"
      img={food}
      url={location.href}
    />

    <div id="menu-links">
      <PageHeader img={lakeShore}>
        <BannerCenter title="The Menu" subtitle="Lakeside Dining & Takeout">
          <BannerButton>
            <a href="/lunch-dinner-menu.pdf">Lunch & Dinner</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="/thanksgiving-menu.pdf">Thanksgiving Buffet Menu</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="/game-day-menu.pdf">Game Day Menu</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="/brunch-menu.pdf">Saturday & Sunday Brunch</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="/kids-menu.pdf">Kids Menu</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="/bar-menu.pdf">Bar Menu</a>
          </BannerButton>
        </BannerCenter>
      </PageHeader>
      <QuickInfo></QuickInfo>
      <HomeHeader img={food}></HomeHeader>
    </div>
  </Layout>
)

export default MenuPage
